import React, { useEffect, useState } from "react"
import { isBrowser } from "./utils"

export const colorsContext = React.createContext()

const Provider = props => {
  const [savedColors, setSavedColors] = useState([])
  const [isEnglish, setIsEnglish] = useState("en")

  const handleChangeLanguage = value => {
    setIsEnglish(value)
  }
  useEffect(() => {
    if (isBrowser) {
      const userLanguage = navigator?.language
      setIsEnglish(!userLanguage?.startsWith("es"))
    }
  }, [])
  return (
    <colorsContext.Provider
      value={{
        savedColors,
        addColor: color =>
          setSavedColors(savedColors => [...savedColors, color]),
        isEnglish,
        handleChangeLanguage,
      }}
    >
      {props.children}
    </colorsContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
