const axios = require("axios")

export const HUBSPOT_API = axios.create({
  baseURL: "https://api.hsforms.com/submissions/v3/integration/submit",
})

export const IPIFY_API = axios.create({
  baseURL: "https://api.ipify.org?format=json",
})

export const gravityFormsApi = axios.create({
  baseURL: `https://admin.domain.com/gravityformsapi/
`,
})

export const WORDPRESS_API = axios.create({
  baseURL: "https://admin-c3.aftershock.agency/wp-json/wp/v2/",
})

export const mediaApi = axios.create({
  baseURL: `https://admin-c3.aftershock.agency/wp-json`,
})
