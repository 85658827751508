import queryString from "query-string"
import Cookies from "js-cookie"
import {
  gravityFormsApi,
  HUBSPOT_API,
  IPIFY_API,
  mediaApi,
  WORDPRESS_API,
} from "./apis/apis"
import CryptoJS from "crypto-js"

export const IS_PRODUCTION = process.env.GATSBY_ACTIVE_ENV === "production"

export const isBrowser = () => typeof window !== "undefined"

export const getLocalStorageItem = key => {
  if (typeof window !== "undefined" && window) {
    return window.localStorage.getItem(key)
  }
  return null
}

export const setLocalStorageItem = (key, value) => {
  if (typeof window !== "undefined" && window) {
    window.localStorage.setItem(key, value)
  }
}

export const catchAsync = fn => {
  return async (...props) => {
    return await fn(...props).catch(err => {
      console.log("err :>> ", err.message)
      return null
    })
  }
}

export const getColor = (color, theme) => {
  switch (color) {
    case "primary":
      return theme.palette.primary.main
    case "secondary":
      return theme.palette.secondary.main
    case "tertiary":
      return theme.palette.tertiary.main
    case "content":
      return theme.palette.text.content
    default:
      return theme.palette.text.primary
  }
}

export const textEllipsis = (
  str,
  maxLength,
  { side = "end", ellipsis = "..." } = {}
) => {
  if (str.length > maxLength) {
    switch (side) {
      case "start":
        return ellipsis + str.slice(-(maxLength - ellipsis.length))
      case "end":
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis
    }
  }
  return str
}

export const setFormUtmParams = setValue => {
  if (typeof window !== "undefined" && window) {
    const parameters = window.location.search
      ? queryString.parse(window.location.search)
      : ""
    const params = [
      "utm_medium",
      "utm_source",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "utm_name",
    ]
    if (parameters !== "") {
      params.map(param => {
        if (param in parameters) {
          setValue(param, parameters[param])
        }
      })
    }
  }
}
export const removeSpecialChar = string => string.replace(/&amp;/g, "&")

export const submitHubspotForm = async (data, portalId, formId) => {
  let fields = []
  Object.entries(data).map(item => {
    fields.push({ name: item[0], value: item[1] })
  })

  const isBrowser = typeof window !== "undefined"
  const hutk = isBrowser ? Cookies.get("hubspotutk") : null
  const pageUri = isBrowser ? window.location.href : null
  const pageName = isBrowser ? document.title : null
  const ipAddress = await IPIFY_API.get()

  const context =
    ipAddress && ipAddress.data.ip
      ? {
          ipAddress: ipAddress.data.ip,
          hutk,
          pageUri,
          pageName,
        }
      : {
          hutk,
          pageUri,
          pageName,
        }

  try {
    const res = await HUBSPOT_API.post(
      `/${portalId}/${formId}`,
      JSON.stringify({
        submittedAt: Date.now(),
        fields,
        context,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept:
            "application/json, application/xml, text/plain, text/html, *.*",
        },
      }
    )

    return res
  } catch (e) {
    return e.response
  }
}

const calculateSignature = (stringToSign, privateKey) => {
  const hash = CryptoJS.HmacSHA1(stringToSign, privateKey)
  const base64 = hash.toString(CryptoJS.enc.Base64)
  return encodeURIComponent(base64)
}

export const submitGravityForm = (data, formId) => {
  const d = new Date(),
    expiration = 3600,
    unixtime = parseInt(d.getTime() / 1000),
    future_unixtime = unixtime + expiration,
    publicKey = process.env.GF_PUB_KEY,
    privateKey = process.env.GF_PRIV_KEY,
    method = "POST",
    route = `forms/${formId}/submissions`,
    stringToSign =
      publicKey + ":" + method + ":" + route + ":" + future_unixtime,
    sig = calculateSignature(stringToSign, privateKey)

  const uri =
    route +
    "?api_key=" +
    publicKey +
    "&signature=" +
    sig +
    "&expires=" +
    future_unixtime

  const values = {
    input_values: {
      data,
    },
  }

  return gravityFormsApi
    .post(uri, values, {
      headers: { "Content-Type": "application/json" },
    })
    .then(response => {
      return response
    })
}

export const idOdd = num => {
  return num % 2
}

export const getPostData = searchTerm =>
  WORDPRESS_API.get(`/posts?search=${searchTerm}&_embed&per_page=100`)
    .then(response => response.data)
    .catch(error => error)

export const getPageData = searchTerm =>
  WORDPRESS_API.get(`/pages?search=${searchTerm}&_embed&per_page=100`)
    .then(response => response.data)
    .catch(error => error)

export const getAllPosts = async () => {
  try {
    const response = await mediaApi.get("/wp/v2/posts")
    return response?.data
  } catch (error) {
    console.log("error :>> ", error)
    return null
  }
}

export const updateWpPost = async body => {
  if (!IS_PRODUCTION) return null

  try {
    const response = await window.fetch("/api/update-post", {
      method: "POST",
      body: JSON.stringify(body),
    })
    return response
  } catch (error) {
    console.log("error :>> ", error)
    return error
  }
}
export const getCircleColor = type => {
  switch (type) {
    case "active":
      return "#013B82"
    case "headquarter":
      return "#F3A94C"
    case "pending":
      return "#767676"
    default:
      return "#013B82"
  }
}

export const emailRegexExpression =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const phoneRegexExpression =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/

export const getRHFErrorMessage = (errors, name, rules) => {
  const splitName = name.split(".")
  const getError = () => {
    if (splitName.length === 2) {
      return errors[splitName[0]]?.[splitName[1]]
    }
    if (splitName.length === 3) {
      return errors[splitName[0]]?.[splitName[1]]?.[splitName[2]]
    }
    return errors[name]
  }
  const error = getError()

  if (error) {
    // console.log("error :>> ", error);
    switch (error.type) {
      case "valueAsNumber":
        return `${name} is not a valid number`
      case "required":
        return error.message ? error.message : "This is a required field"
      case "min":
        return `Min ${rules.min}`
      case "max":
        return `Max ${rules.max}`
      case "maxLength":
        return `Text too long. Max ${rules.maxLength}`
      case "minLength":
        return `Text too short. Min ${rules.minLength}`
      case "pattern":
        return `${name} is not valid`
      case "validate":
        //console.log(errors)
        return error.message
      default:
        return ""
    }
  }
}

export const subscribeNewsletter = catchAsync(async formData => {
  const res = await window.fetch(`/api/subscribe-newsletter`, {
    method: `POST`,
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(formData),
  })

  return res.json()
})
