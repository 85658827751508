import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 95,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  fonts: {
    primary: "Gilroy, sans-serif",
    secondary: "Gilroy, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#013B82",
      light: "#013677",
      dark: "#013B82",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#00AEEF",
      light: "#00AEEF",
      dark: "#00AEEF",
    },
    tertiary: {
      main: "#f3a94c",
      light: "#FAFAFA",
      dark: "#4D4D4D",
    },
    text: {
      primary: "#212121",
      secondary: "#848484",
      content: "#797979",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
      dark: "#121212",
      error: "#d32f2f",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Gilroy", "Open Sans", "Arial", sans-serif`,
  },
})
export default theme
